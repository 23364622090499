<template>
  <div>
    <div>
      <h3>Emits</h3>
    </div>
    <div>
      <span
        v-for="option in keyOptions"
        :key="option">
        <input
          type="radio"
          disabled
          :id="option"
          :value="option"
          v-model="selectedKey"
          @change="handleCheckedKeyChange"/>
        <label
          :for="option">
          {{option}}
        </label>
      </span>
    </div>
    <div>
      <!-- @Q@ 暫先寫死修改 deep="1" -->
      <vue-json-pretty
        class="jsontree"
        v-if="!editMode&&isShowJsonViewer"
        :data="currentEmit"
        :selectable-type="'tree'">
      </vue-json-pretty>
    </div>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import { jsonParse, jsonStringify } from './jsonParser.js'
import debounce from './debounce'

let watchEmits = {}

export default {
  name: 'emit-panel',
  props: {
    emits: {
      required: false,
      default () {
        return {}
      }
    },
  },
  components: {
    VueJsonPretty
  },
  data () {
    return {
      isShowJsonViewer: true,
      editMode: false,
      keyOptions: [], // checkbox options
      selectedKey: '', // checkbox value
      currentEmit: {},
      debounceSetCurrentEmit: () => {},
    }
  },
  // computed: {
  //   currentEmit () {
  //     if (this.emits[this.selectedKey]) {
  //       return this.emits[this.selectedKey]
  //     }
  //     return ''
  //   }
  // },
  created () {
    this.keyOptions = Object.keys(this.emits)

    // @Q@ 暫先改成 debouce
    this.debounceSetCurrentEmit = debounce(this.setCurrentEmit, 200)
  },
  watch: {
    emits: {
      handler (newData, oldData) {
        if (!newData || !oldData) {
          return
        }
        this.debounceSetCurrentEmit({ newData, oldData })
      },
    }
  },
  methods: {
    handleCheckedKeyChange (key) {
      this.selectedKey = key
    },
    setCurrentEmit ({ newData, oldData }) {
      // 取得資料變化的emit
      for (let key in newData) {
        try {
          const newEmitValue = jsonStringify(newData[key])
          const oldEmitValue = jsonStringify(oldData[key])
          if (newEmitValue != oldEmitValue) {
            this.selectedKey = key
            break
          }
        } catch (e) {
          console.error(e)
        }
      }

      this.currentEmit = newData[this.selectedKey]
    },
    test (v) {
      console.log(v)
    }
  }
  
}
</script>