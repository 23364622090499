<template>
  <ComponentSandbox
    :props="{
      ...data.props,
      module: String(data.props.module)
    }"
    :emits="data.emits">
    <template v-slot="{ syncProps, handleEmits }">
      <!-- <D3ModuleContainer
        :module="data.props.module"
        :params="syncProps.params"
        :data="syncProps.data"
        :datum="syncProps.datum"
        :dataset="syncProps.dataset"
        :filter="syncProps.filter"
        :width="syncProps.width"
        :height="syncProps.height"
        :forceFit="syncProps.forceFit"
        :zoom="syncProps.zoom"
        @click="handleEmits.click ? handleEmits.click : () => {}"
        @mouseover="handleEmits.mouseover ? handleEmits.mouseover : () => {}"
        @mousemove="handleEmits.mousemove ? handleEmits.mousemove : () => {}"
        @mouseout="handleEmits.mouseout ? handleEmits.mouseout : () => {}"
        @dragStart="handleEmits.dragStart ? handleEmits.dragStart : () => {}"
        @drag="handleEmits.drag ? handleEmits.drag : () => {}"
        @dragEnd="handleEmits.dragEnd ? handleEmits.dragEnd : () => {}"
        @zoom="handleEmits.zoom ? handleEmits.zoom : () => {}">
      </D3ModuleContainer> -->
      <D3ModuleContainer
        v-bind="syncProps"
        :module="data.props.module"
        v-on="handleEmits">
      </D3ModuleContainer>
    </template>
  </ComponentSandbox>
</template>

<script>
import ComponentSandbox from '@/assets/vue-component-sandbox/lib/main.js'
import 'vue-component-sandbox/lib/styles.css'
import { D3ModuleContainer } from '@bpchart/vue'
// import { DynamicModuleContainer } from './DynamicModuleContainer.vue'

export default {
  props: {
    data: {
      default () {
        return {
          props: {},
          emits: {}
        }
      }
    }
  },
  components: {
    ComponentSandbox,
    D3ModuleContainer
    // DynamicModuleContainer
  },
}
</script>

<style lang="scss">
.component-sandbox .display-block.expanded {
  width: 100%;
}
.component-sandbox-previewpanel .expand-style {
  overflow: hidden;
}
</style>