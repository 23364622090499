<template>
  <div class="company-tag">
    <div class="tag-list tag-list--system">
      <el-tag class="tag-item" size="small" v-for="(tag,index) in tags" :key="index">
        {{tag}}
      </el-tag>
    </div>
    <!-- <div class="tag-list tag-list--custom">
      <el-tag 
        class="tag-item" 
        size="small" 
        closable 
        v-for="(userLabel,index) in selfUserLabels"
        :disable-transitions="false"
        @close="deleteUserLabel(userLabel)"
        :key="index">
        {{userLabel.label}}
      </el-tag>
      <el-input
        class="tag-input"
        v-model="addingUserLabel"
        v-if="addingUserLabelVisible"
        ref="userLabelInput"
        size="mini"
        @keypress.enter="saveAddingUserLabel"
        @keyup.esc="inactiveUserLabelInput"
        @blur="saveAddingUserLabel"
      >
      </el-input>
      <el-button v-else class="add-tag-btn" size="mini" @click="activeUserLabelInput">
        <font-awesome-icon :icon="['fas', 'plus']"/>
        <font-awesome-icon :icon="['fas', 'tag']"/>
      </el-button>
    </div> -->
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
// import { companyEditLabel } from '@/apis/index'

export default {
  props: {
    uniID: {
      required: true
    },
    tags: {
      default () {
        return []
      }
    }
  },
  data () {
    return {
      // addingUserLabel: '',
      // addingUserLabelVisible: false,
      // selfUserLabels: [],
      // systemUserLabels: [],
    }
  },
  computed: {
    // ...mapGetters([
    //   'getterUserEmail'
    // ]),
  },
  watch: {
    // userLabels: {
    //   handler (userLabels) {
    //     this.selfUserLabels = JSON.parse(JSON.stringify(userLabels.filter(d => d.status === 'self')))
    //     this.systemUserLabels = JSON.parse(JSON.stringify(userLabels.filter(d => d.status === 'open')))
    //   },
    //   immediate: true
    // }
  },
  methods: {
    // activeUserLabelInput () {
    //   this.addingUserLabelVisible = true
    //   this.$nextTick(_ => {
    //     this.$refs.userLabelInput.$refs.input.focus()
    //   })
    // },
    // inactiveUserLabelInput () {
    //   this.addingUserLabelVisible = false
    //   this.addingUserLabel = ''
    // },
    // async deleteUserLabel (userLabel) {
    //   const result = await companyEditLabel({
    //     uniID: this.uniID,
    //     label: userLabel.label,
    //     user: this.getterUserEmail,
    //     status: 'close'
    //   })
    //   if (!result.success) {
    //     return
    //   }
    //   this.selfUserLabels = this.selfUserLabels
    //     .filter(d => d.label !== userLabel.label)
      
    //   this.inactiveUserLabelInput()
    // },
    // async saveAddingUserLabel () {
    //   if (!this.addingUserLabel) {
    //     this.inactiveUserLabelInput()
    //     return
    //   }
    //   const result = await companyEditLabel({
    //     uniID: this.uniID,
    //     label: this.addingUserLabel,
    //     user: this.getterUserEmail,
    //     status: 'self'
    //   })
    //   if (!result.success) {
    //     return
    //   }
    //   this.selfUserLabels.push({
    //     label: this.addingUserLabel,
    //     user: this.getterUserEmail,
    //     status: 'self'
    //   })
    //   this.inactiveUserLabelInput()
    // },
  }
}
</script>
