<template>
  <div class="page-container blur-box">
    <div class="page-container-header">
      <div class="basic-info">
        <div class="logo">
          <!-- <img src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png" alt="XX Logo"> -->
          <!-- <div
            style="font-size:72px;text-align:center;line-height:110px;color:#c0c4cc"
            :alt="basic.company_name">
            {{avatarCoverText}}
          </div> -->
        </div>
        <div class="title">{{info.moduleTitle}}</div>
        <div class="subtitle">{{info.moduleName}}</div>
        <!-- <div class="hint">更新時間：{{formatEmpty(companyData._max_fields_datetime_update)}}</div> -->
      </div>
    </div>
    <nav class="page-nav">
      <ul>
        <li
          v-for="(data,index) in chartPreviewData"
          :key="index"
          :class="{ active: index == tabNo }">
          <router-link
            :to="{
              query: {
                tab: index
              },
            }">{{data.title}}
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="page-container-main page-container-main--grid" id="content">
      <div class="detail-card colspan-10">
        <div class="normal-list">
          <div
            class="normal-list-item normal-list-item--two-cols">
            <div class="normal-list-item-label">
              類別：
            </div>
            <div class="normal-list-item-value">
              {{info.baseModuleName}} ({{info.baseModuleTitle}})
            </div>
          </div>
          <div
            class="normal-list-item normal-list-item--two-cols">
            <div class="normal-list-item-label">
              d3-module import指令：
            </div>
            <div class="normal-list-item-value" style="background-color:#efefef">
              import { {{info.moduleName}} } from '@bpchart/d3-modules/{{info.baseModuleName}}'
            </div>
          </div>
          <div v-if="info.description" class="normal-list-item normal-list-item--two-cols">
            <div class="normal-list-item-label">
              說明：
            </div>
            <div class="normal-list-item-value">
              {{formatEmpty(info.description)}}
            </div>
          </div>
        </div>
      </div>
      <div class="detail-card colspan-10">
        <Tags
          :uniID="info.uniID"
          :tags="info.tags">
        </Tags>
      </div>
      <div class="detail-card colspan-10">
        <div class="detail-card-header">
          <div class="detail-card-title">Component Sandbox</div>
          <div class="detail-card-toolbar">
            <div class="detail-card-toolbar-item">
              <div class="hint">* Sandbox工具可能會因為大量資料的同步顯示，造成畫面卡頓；如不需使用 Sandbox工具可
                <router-link :to="routerTestLinkTo(moduleName, tabNo)" target="_blank">
                  點此另開整頁模式視窗
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-card-content">
          <ComponentSandboxCard
            v-if="currentTabData"
            :key="forceUpdateKey"
            :data="currentTabData.component">
          </ComponentSandboxCard>
        </div>
      </div>
    </div>
    <div class="page-function">
      <ul>
        <li>
          <!-- <el-tooltip class="item" effect="dark" content="收藏" placement="left">
            <button
              class="share-btn"
              :class="{active:isCollection}"
              @click="toggleCollection">
              <font-awesome-icon :icon="['fas', 'bookmark']"/>
            </button>
          </el-tooltip> -->
          <el-tooltip class="item" effect="dark" content="整頁" placement="left">
            <router-link :to="routerTestLinkTo(moduleName, tabNo)" target="_blank">
              <button class="share-btn">
                <font-awesome-icon :icon="['fas', 'share']"/>
                <!-- <fa icon="bookmark" type="fas" class="share-btn-icon"></fa> -->
              </button>
            </router-link>
          </el-tooltip>
        </li>
        <!-- <li>
          <el-tooltip class="item" effect="dark" content="分享" placement="left">
            <button class="share-btn">
              <font-awesome-icon :icon="['fas', 'share']"/>
            </button>
          </el-tooltip>
        </li> -->
        <!-- <li>
          <el-tooltip class="item" effect="dark" content="下載" placement="left">
            <button
              class="share-btn"
              :class="{active:isDownloadLoading}"
              @click="handleDownload">
              <i class="el-icon-loading" v-if="isDownloadLoading"/>
              <font-awesome-icon v-else :icon="['fas', 'download']"/>
            </button>
          </el-tooltip>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import demoList from '@/const/demoList.ts'
import { formatEmpty, formatNumberWithComma } from '@/utils/utils.js'
import * as demoData from '@/const/demoData/index.ts'
import Tags from '@/components/Tags.vue'
import ComponentSandboxCard from '@/components/ComponentSandboxCard.vue'

export default {
  props: {
    moduleName: {
      default () {
        return ''
      }
    }
  },
  components: {
    Tags,
    ComponentSandboxCard
  },
  data () {
    return {
      formatEmpty,
      formatNumberWithComma,
      tabNo: 0,
      forceUpdateKey: Date.now()
    }
  },
  computed: {
    info () {
      const findItem = demoList.find(d => d.moduleName === this.moduleName)
      return findItem || {}
    },
    isCollection: {
      get () {
        return false
      },
      async set (d) {
        console.log(d)
      }
    },
    chartPreviewData () {
      return demoData[this.moduleName] || []
    },
    currentTabData () {
      return demoData[this.moduleName] ? demoData[this.moduleName][this.tabNo] : null
    }
  },
  watch: {
    '$route.query': {
      handler (query) {
        if (!query || query.tab == null) {
          return
        }
        const tab = Number(query.tab)
        this.tabNo = tab
        this.forceUpdateKey = Date.now() // 強制更新
      },
      immediate: true
    }
  },
  methods: {
    toggleCollection () {
      this.isCollection = !this.isCollection
    },
    routerTestLinkTo (moduleName, no = 0) {
      return {
        name: 'fullscreen-modulename',
        params: {
          moduleName
        },
        query: {
          no
        }
      }
    }
  }
}
</script>

<style lang="scss">
.component-sandbox .display-block.expanded {
  background: #ffffff;
}
</style>