import { BaseModuleTitle } from '@/types/demo';
import _Template from './demoData/_Template/資料1';
import AxisColumn from './demoData/AxisColumn/所有預設值';
import AxisColumnTwoScales from './demoData/AxisColumnTwoScales/所有預設值';
import AxisDateColumn from './demoData/AxisDateColumn/所有預設值';
import AxisDateColumnTwoScales from './demoData/AxisDateColumnTwoScales/所有預設值';
import AxisDateRank from './demoData/AxisDateRank/所有預設值';
import AxisDiverging from './demoData/AxisRowDiverging/所有預設值';
import AxisRow from './demoData/AxisRow/最簡範例';
import ChartArcPie from './demoData/ChartArcPie/甜甜圈圖及中間文字';
import ChartBubbleGroup from './demoData/ChartBubbleGroup/一般範例';
import ChartColumnAreaStack from './demoData/ChartColumnAreaStack/最簡範例';
import ChartColumnBar from './demoData/ChartColumnBar/彩色bar';
import ChartColumnBarGroup from './demoData/ChartColumnBarGroup/一般範例';
import ChartColumnBarPNGroup from './demoData/ChartColumnBarPNGroup/一般範例';
import ChartColumnBarStack from './demoData/ChartColumnBarStack/一般範例';
import ChartColumnLine from './demoData/ChartColumnLine/一般範例';
import ChartColumnTwoScalesBarAndLine from './demoData/ChartColumnTwoScalesBarAndLine/一般範例';
import ChartColumnTwoScalesBarGroupAndLine from './demoData/ChartColumnTwoScalesBarGroupAndLine/一般範例';
import ChartColumnTwoScalesBarStackAndLine from './demoData/ChartColumnTwoScalesBarStackAndLine/一般範例';
import ChartColumnTwoScalesLine from './demoData/ChartColumnTwoScalesLine/最簡範例';
import ChartDateColumnLine from './demoData/ChartDateColumnLine/最簡範例';
import ChartDateColumnBar from './demoData/ChartDateColumnBar/最簡範例';
import ChartDateColumnBarStack from './demoData/ChartDateColumnBarStack/最簡範例';
import ChartDateColumnTwoScalesBarAndLine from './demoData/ChartDateColumnTwoScalesBarAndLine/最簡範例';
import ChartDateColumnTwoScalesBarStackAndLine from './demoData/ChartDateColumnTwoScalesBarStackAndLine/最簡範例';
// import ChartDateHistogramLine from './demoData/ChartDateHistogramLine/一般範例'
import ChartDateRankBubbles from './demoData/ChartDateRankBubbles/highlight日期資料';
import ChartDirectedDagre from './demoData/ChartDirectedDagre/實告_三創數位';
import ChartDirectedDagreDbs from './demoData/ChartDirectedDagreDbs/今日股份有限公司';
import ChartDirectedForce from './demoData/ChartDirectedForce/實告_三創數位';
// import ChartDonutMiddleInfo from './demoData/CharntDonutMiddleInfo/所有預設值'
// import ChartDonutPie from './demoData/ChartDonutPie/所有預設值'
import ChartImageRowStack from './demoData/ChartImageRowStack/市民儀表板';
// import ChartInfluenceRadar from './demoData/ChartInfluenceRadar/一般範例'
import ChartRowBar from './demoData/ChartRowBar/最簡範例';
import ChartRowBarGroup from './demoData/ChartRowBarGroup/最簡範例';
import ChartRowBarPNGroup from './demoData/ChartRowBarPNGroup/最簡範例';
import ChartRowBarLayout from './demoData/ChartRowBarLayout/layout_混合堆疊及群組';
import ChartRowBarStack from './demoData/ChartRowBarStack/最簡範例';
import ChartRowDivergingBar from './demoData/ChartRowDivergingBar/最簡範例';
import ChartScoutRouteLines from './demoData/ChartScoutRouteLines/三創數位股份有限公司';
import ChartScoutRouteTree from './demoData/ChartScoutRouteTree/三創數位股份有限公司';
import ChartScoutRouteTree2 from './demoData/ChartScoutRouteTree2/今日股份有限公司';
import GraphicArcPie from './demoData/GraphicArcPie/所有預設值';
import GraphicColumnAreaStack from './demoData/GraphicColumnAreaStack';
import GraphicColumnBar from './demoData/GraphicColumnBar/所有預設值';
import GraphicColumnBarDiverging from './demoData/GraphicColumnBarDiverging/所有預設值';
import GraphicColumnBarGroup from './demoData/GraphicColumnBarGroup/所有預設值';
// import GraphicColumnBarPNGroup from './demoData/GraphicColumnBarPNGroup/所有預設值'
import GraphicColumnBarStack from './demoData/GraphicColumnBarStack/所有預設值';
import GraphicColumnLine from './demoData/GraphicColumnLine/所有預設值';
import GraphicDot from './demoData/GraphicDot/所有預設值';
import GraphicImageStack from './demoData/GraphicImageRowStack/市民儀表板';
import GraphicRowBar from './demoData/GraphicRowBar/最簡範例';
import GraphicRowBarDiverging from './demoData/GraphicRowBarDiverging/最簡範例';
import GraphicRowBarGroup from './demoData/GraphicRowBarGroup/最簡範例';
import GraphicRowBarLayout from './demoData/GraphicRowBarLayout/layout設定';
// import GraphicRowBarPNGroup from './demoData/GraphicRowBarPNGroup/最簡範例'
import GraphicRowBarStack from './demoData/GraphicRowBarStack/最簡範例';
import LabelArcPie from './demoData/LabelArcPie';
import LegendBottom from './demoData/LegendBottom/一般範例';
import SliderDateRangeLine from './demoData/SliderDateRangeLine/自定範圍';
import TooltipAside from './demoData/TooltipAside/所有預設值';
import TooltipFollowing from './demoData/TooltipFollowing/所有預設值';
import UtilAuxDateLabel from './demoData/UtilAuxDateLabel/最簡範例';
import UtilAuxLine from './demoData/UtilAuxLine/最簡範例';
import UtilGraphicBarLabel from './demoData/UtilGraphicBarLabel/最簡範例';
export default [
    {
        uniID: '_Template',
        // image: '',
        baseModuleName: '_template',
        baseModuleTitle: BaseModuleTitle._template,
        moduleName: '_Template',
        moduleTitle: '開發範本',
        previewData: _Template,
        description: '',
        tags: ['範本']
    },
    {
        uniID: 'AxisColumn',
        // image: '/images/chartInfo/donut_middle_info.png',
        baseModuleName: 'axisColumn',
        baseModuleTitle: BaseModuleTitle.axisColumn,
        moduleName: 'AxisColumn',
        moduleTitle: '直立圖軸',
        previewData: AxisColumn,
        description: '',
        tags: []
    },
    {
        uniID: 'AxisColumnTwoScales',
        // image: '/images/chartInfo/donut_middle_info.png',
        baseModuleName: 'axisColumnTwoScales',
        baseModuleTitle: BaseModuleTitle.axisColumnTwoScales,
        moduleName: 'AxisColumnTwoScales',
        moduleTitle: '直立雙Y軸圖軸',
        previewData: AxisColumnTwoScales,
        description: '',
        tags: []
    },
    {
        uniID: 'AxisDateColumn',
        // image: '/images/chartInfo/donut_middle_info.png',
        baseModuleName: 'axisDateColumn',
        baseModuleTitle: BaseModuleTitle.axisDateColumn,
        moduleName: 'AxisDateColumn',
        moduleTitle: '直立日期圖軸',
        previewData: AxisDateColumn,
        description: '',
        tags: []
    },
    {
        uniID: 'AxisDateColumnTwoScales',
        // image: '/images/chartInfo/donut_middle_info.png',
        baseModuleName: 'axisDateColumnTwoScales',
        baseModuleTitle: BaseModuleTitle.axisDateColumnTwoScales,
        moduleName: 'AxisDateColumnTwoScales',
        moduleTitle: '直立日期雙Y軸圖軸',
        previewData: AxisDateColumnTwoScales,
        description: '',
        tags: []
    },
    {
        uniID: 'AxisDateRank',
        // image: '/images/chartInfo/donut_middle_info.png',
        baseModuleName: 'axisDateRank',
        baseModuleTitle: BaseModuleTitle.axisDateRank,
        moduleName: 'AxisDateRank',
        moduleTitle: '日期排名圖軸',
        previewData: AxisDateRank,
        description: '',
        tags: []
    },
    {
        uniID: 'AxisDiverging',
        // image: '/images/chartInfo/donut_middle_info.png',
        baseModuleName: 'axisDiverging',
        baseModuleTitle: BaseModuleTitle.axisDiverging,
        moduleName: 'AxisDiverging',
        moduleTitle: '雙向圖軸',
        previewData: AxisDiverging,
        description: '',
        tags: []
    },
    {
        uniID: 'AxisRow',
        // image: '/images/chartInfo/donut_middle_info.png',
        baseModuleName: 'axisRow',
        baseModuleTitle: BaseModuleTitle.axisRow,
        moduleName: 'AxisRow',
        moduleTitle: '橫向圖軸',
        previewData: AxisRow,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartArcPie',
        // image: '/images/chartInfo/donut_middle_info.png',
        baseModuleName: 'chartArc',
        baseModuleTitle: BaseModuleTitle.chartArc,
        moduleName: 'ChartArcPie',
        moduleTitle: '圓餅圖',
        previewData: ChartArcPie,
        description: '',
        tags: ['北市府儀表板']
    },
    // {
    //   uniID: 'ChartDonutPie',
    //   // image: '/images/chartInfo/donut_middle_info.png',
    //   baseModuleName: 'chartDonut',
    //   baseModuleTitle: BaseModuleTitle.chartDonut,
    //   moduleName: 'ChartDonutPie',
    //   moduleTitle: '甜甜圈圓餅圖',
    //   previewData: ChartDonutPie,
    //   description: '',
    //   tags: []
    // },
    // {
    //   uniID: 'ChartDonutMiddleInfo',
    //   // image: '/images/chartInfo/donut_middle_info.png',
    //   baseModuleName: 'chartDonut',
    //   baseModuleTitle: BaseModuleTitle.chartDonut,
    //   moduleName: 'ChartDonutMiddleInfo',
    //   moduleTitle: '甜甜圈資訊圖',
    //   previewData: ChartDonutMiddleInfo,
    //   description: '',
    //   tags: ['實告', '蛛思widget']
    // },
    {
        uniID: 'ChartColumnAreaStack',
        // image: '/images/chartInfo/bar.png',
        baseModuleName: 'chartColumn',
        baseModuleTitle: BaseModuleTitle.chartColumn,
        moduleName: 'ChartColumnAreaStack',
        moduleTitle: '直立區塊堆疊圖',
        previewData: ChartColumnAreaStack,
        description: '',
        tags: ['北市府儀表板']
    },
    {
        uniID: 'ChartColumnBar',
        // image: '/images/chartInfo/bar.png',
        baseModuleName: 'chartColumn',
        baseModuleTitle: BaseModuleTitle.chartColumn,
        moduleName: 'ChartColumnBar',
        moduleTitle: '直立長條圖',
        previewData: ChartColumnBar,
        description: '',
        tags: ['實告']
    },
    {
        uniID: 'ChartColumnBarGroup',
        // image: '/images/chartInfo/bar_group.png',
        baseModuleName: 'chartColumn',
        baseModuleTitle: BaseModuleTitle.chartColumn,
        moduleName: 'ChartColumnBarGroup',
        moduleTitle: '直立群組長條圖',
        previewData: ChartColumnBarGroup,
        description: '',
        tags: ['實告']
    },
    {
        uniID: 'ChartColumnBarPNGroup',
        // image: '/images/chartInfo/bar_group.png',
        baseModuleName: 'chartColumn',
        baseModuleTitle: BaseModuleTitle.chartColumn,
        moduleName: 'ChartColumnBarPNGroup',
        moduleTitle: '直立正負值群組長條圖',
        previewData: ChartColumnBarPNGroup,
        description: '',
        tags: ['北市府儀表板']
    },
    {
        uniID: 'ChartColumnBarStack',
        // image: '/images/chartInfo/bar_group.png',
        baseModuleName: 'chartColumn',
        baseModuleTitle: BaseModuleTitle.chartColumn,
        moduleName: 'ChartColumnBarStack',
        moduleTitle: '直立堆疊長條圖',
        previewData: ChartColumnBarStack,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartColumnLine',
        // image: '/images/chartInfo/bar_group.png',
        baseModuleName: 'chartColumn',
        baseModuleTitle: BaseModuleTitle.chartColumn,
        moduleName: 'ChartColumnLine',
        moduleTitle: '折線圖',
        previewData: ChartColumnLine,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartColumnTwoScalesBarAndLine',
        // image: '/images/chartInfo/bar_with_line.png',
        baseModuleName: 'chartColumnTwoScales',
        baseModuleTitle: BaseModuleTitle.chartColumnTwoScales,
        moduleName: 'ChartColumnTwoScalesBarAndLine',
        moduleTitle: '雙Y軸長條折線圖',
        previewData: ChartColumnTwoScalesBarAndLine,
        description: '',
        tags: ['實告']
    },
    {
        uniID: 'ChartColumnTwoScalesBarGroupAndLine',
        // image: '/images/chartInfo/bar_with_line.png',
        baseModuleName: 'chartColumnTwoScales',
        baseModuleTitle: BaseModuleTitle.chartColumnTwoScales,
        moduleName: 'ChartColumnTwoScalesBarGroupAndLine',
        moduleTitle: '雙Y軸長條群組折線圖',
        previewData: ChartColumnTwoScalesBarGroupAndLine,
        description: '',
        tags: ['實告']
    },
    {
        uniID: 'ChartColumnTwoScalesBarStackAndLine',
        // image: '/images/chartInfo/bar_with_line.png',
        baseModuleName: 'chartColumnTwoScales',
        baseModuleTitle: BaseModuleTitle.chartColumnTwoScales,
        moduleName: 'ChartColumnTwoScalesBarStackAndLine',
        moduleTitle: '雙Y軸長條堆疊折線圖',
        previewData: ChartColumnTwoScalesBarStackAndLine,
        description: '',
        tags: ['北市府儀表板']
    },
    {
        uniID: 'ChartColumnTwoScalesLine',
        // image: '/images/chartInfo/bar_with_line.png',
        baseModuleName: 'chartColumnTwoScales',
        baseModuleTitle: BaseModuleTitle.chartColumnTwoScales,
        moduleName: 'ChartColumnTwoScalesLine',
        moduleTitle: '雙Y軸折線圖',
        previewData: ChartColumnTwoScalesLine,
        description: '',
        tags: ['北市府儀表板']
    },
    // {
    //   uniID: 'ChartInfluenceRadar',
    //   image: '/images/chartInfo/bubble_radar.png',
    //   moduleTitle: '泡泡雷達',
    //   moduleName: 'ChartInfluenceRadar',
    //   baseModuleTitle: BaseModuleTitle.chart,
    //   // vueComponentType: VueComponentType.chart,
    //   description: '',
    //   tags: ['蛛思', '蛛思widget']
    // },
    {
        uniID: 'ChartDirectedDagre',
        // image: '/images/chartInfo/dagre_directed.png',
        baseModuleName: 'chartDirected',
        baseModuleTitle: BaseModuleTitle.chartDirected,
        moduleName: 'ChartDirectedDagre',
        moduleTitle: 'Dagre關聯圖',
        previewData: ChartDirectedDagre,
        description: '',
        tags: ['實告']
    },
    {
        uniID: 'ChartDirectedDagreDbs',
        // image: '/images/chartInfo/dagre_directed.png',
        baseModuleName: 'chartDirected',
        baseModuleTitle: BaseModuleTitle.chartDirected,
        moduleName: 'ChartDirectedDagreDbs',
        moduleTitle: '星展Dagre關聯圖',
        previewData: ChartDirectedDagreDbs,
        description: '',
        tags: ['星展']
    },
    {
        uniID: 'ChartDirectedForce',
        // image: '/images/chartInfo/force_directed.png',
        baseModuleName: 'chartDirected',
        baseModuleTitle: BaseModuleTitle.chartDirected,
        moduleName: 'ChartDirectedForce',
        moduleTitle: '力導向關聯圖',
        previewData: ChartDirectedForce,
        description: '',
        tags: ['實告', '星展']
    },
    // {
    //   uniID: 'CardChoose',
    //   image: '/images/chartInfo/card_choose.png',
    //   moduleTitle: '蛛思圖表卡',
    //   moduleName: 'CardChoose',
    //   baseModuleTitle: BaseModuleTitle.none,
    //   // vueComponentType: VueComponentType.container,
    //   description: '',
    //   tags: ['蛛思']
    // },
    // {
    //   uniID: 'ChartDateHistogramLine',
    //   // image: '/images/chartInfo/chart_date_histogram_lines.png',
    //   baseModuleName: 'chartDateHistogram',
    //   baseModuleTitle: BaseModuleTitle.chartDateHistogram,
    //   moduleName: 'ChartDateHistogramLine',
    //   moduleTitle: '日期範圍圖',
    //   previewData: ChartDateHistogramLine,
    //   description: '',
    //   tags: ['蛛思']
    // },
    // {
    //   uniID: 'ChooseChartDateHistogramLine',
    //   image: '/images/chartInfo/choose_chart_date_histogram_lines.png',
    //   moduleTitle: '（範例）蛛思日期範圍圖',
    //   moduleName: 'ChooseChartDateHistogramLine',
    //   baseModuleTitle: BaseModuleTitle.chartDateHistogram,
    //   // vueComponentType: VueComponentType.card,
    //   description: '',
    //   tags: ['蛛思']
    // },
    {
        uniID: 'ChartBubbleGroup',
        // image: '',
        baseModuleName: 'chartBubbleGroup',
        baseModuleTitle: BaseModuleTitle.chartBubbleGroup,
        moduleTitle: '泡泡集合圖',
        moduleName: 'ChartBubbleGroup',
        description: '',
        previewData: ChartBubbleGroup,
        tags: ['蛛思']
    },
    {
        uniID: 'ChartDateColumnBar',
        // image: '',
        baseModuleName: 'chartDateColumn',
        baseModuleTitle: BaseModuleTitle.chartDateColumn,
        moduleName: 'ChartDateColumnBar',
        moduleTitle: '日期長條圖表',
        previewData: ChartDateColumnBar,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartDateColumnBarStack',
        // image: '',
        baseModuleName: 'chartDateColumn',
        baseModuleTitle: BaseModuleTitle.chartDateColumn,
        moduleName: 'ChartDateColumnBarStack',
        moduleTitle: '日期堆疊長條圖表',
        previewData: ChartDateColumnBarStack,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartDateColumnTwoScalesBarAndLine',
        // image: '',
        baseModuleName: 'chartDateColumnTwoScales',
        baseModuleTitle: BaseModuleTitle.chartDateColumnTwoScales,
        moduleName: 'ChartDateColumnTwoScalesBarAndLine',
        moduleTitle: '日期雙Y軸長條及折線圖表',
        previewData: ChartDateColumnTwoScalesBarAndLine,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartDateColumnTwoScalesBarStackAndLine',
        // image: '',
        baseModuleName: 'chartDateColumnTwoScales',
        baseModuleTitle: BaseModuleTitle.chartDateColumnTwoScales,
        moduleName: 'ChartDateColumnTwoScalesBarStackAndLine',
        moduleTitle: '日期雙Y軸堆疊長條及折線圖表',
        previewData: ChartDateColumnTwoScalesBarStackAndLine,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartDateColumnLine',
        // image: '',
        baseModuleName: 'chartDateColumn',
        baseModuleTitle: BaseModuleTitle.chartDateColumn,
        moduleName: 'ChartDateColumnLine',
        moduleTitle: '日期折線圖表',
        previewData: ChartDateColumnLine,
        description: '',
        tags: ['蛛思']
    },
    {
        uniID: 'ChartDateRankBubbles',
        // image: '',
        baseModuleName: 'chartDateRank',
        baseModuleTitle: BaseModuleTitle.chartDateRank,
        moduleName: 'ChartDateRankBubbles',
        moduleTitle: '日期排序泡泡圖',
        previewData: ChartDateRankBubbles,
        description: '',
        tags: ['蛛思']
    },
    {
        uniID: 'ChartRowBar',
        // image: '/images/chartInfo/force_directed.png',
        baseModuleName: 'chartRow',
        baseModuleTitle: BaseModuleTitle.chartRow,
        moduleName: 'ChartRowBar',
        moduleTitle: '橫長條圖表',
        previewData: ChartRowBar,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartRowBarGroup',
        // image: '/images/chartInfo/force_directed.png',
        baseModuleName: 'chartRow',
        baseModuleTitle: BaseModuleTitle.chartRow,
        moduleName: 'ChartRowBarGroup',
        moduleTitle: '橫長條群組圖表',
        previewData: ChartRowBarGroup,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartRowBarLayout',
        // image: '/images/chartInfo/force_directed.png',
        baseModuleName: 'chartRow',
        baseModuleTitle: BaseModuleTitle.chartRow,
        moduleName: 'ChartRowBarLayout',
        moduleTitle: '橫長條布局圖表',
        previewData: ChartRowBarLayout,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartRowBarPNGroup',
        // image: '/images/chartInfo/force_directed.png',
        baseModuleName: 'chartRow',
        baseModuleTitle: BaseModuleTitle.chartRow,
        moduleName: 'ChartRowBarPNGroup',
        moduleTitle: '橫長條正負值群組圖表',
        previewData: ChartRowBarPNGroup,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartRowBarStack',
        // image: '/images/chartInfo/force_directed.png',
        baseModuleName: 'chartRow',
        baseModuleTitle: BaseModuleTitle.chartRow,
        moduleName: 'ChartRowBarStack',
        moduleTitle: '橫長條堆疊圖表',
        previewData: ChartRowBarStack,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartRowDivergingBar',
        // image: '/images/chartInfo/force_directed.png',
        baseModuleName: 'chartRowDiverging',
        baseModuleTitle: BaseModuleTitle.chartRowDiverging,
        moduleName: 'ChartRowDivergingBar',
        moduleTitle: '橫分向長條圖表',
        previewData: ChartRowDivergingBar,
        description: '',
        tags: []
    },
    {
        uniID: 'ChartScoutRouteTree',
        // image: '/images/chartInfo/chart_directed_tree.png',
        baseModuleName: 'chartScoutRoute',
        baseModuleTitle: BaseModuleTitle.chartScoutRoute,
        moduleName: 'ChartScoutRouteTree',
        moduleTitle: '實告實質受益人樹狀圖',
        previewData: ChartScoutRouteTree,
        description: '',
        tags: ['實告']
    },
    {
        uniID: 'ChartScoutRouteLines',
        // image: '',
        baseModuleName: 'chartScoutRoute',
        baseModuleTitle: BaseModuleTitle.chartScoutRoute,
        moduleName: 'ChartScoutRouteLines',
        moduleTitle: '實告股權結構路徑圖',
        previewData: ChartScoutRouteLines,
        description: '',
        tags: ['實告']
    },
    {
        uniID: 'ChartScoutRouteTree2',
        // image: '',
        baseModuleName: 'chartScoutRoute',
        baseModuleTitle: BaseModuleTitle.chartScoutRoute,
        moduleName: 'ChartScoutRouteTree2',
        moduleTitle: '實告股權結構圖v2',
        previewData: ChartScoutRouteTree2,
        description: '',
        tags: ['實告']
    },
    {
        uniID: 'ChartImageRowStack',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'chartImageRowStack',
        baseModuleTitle: BaseModuleTitle.chartImageRowStack,
        moduleName: 'ChartImageRowStack',
        moduleTitle: '圖案堆疊圖表',
        previewData: ChartImageRowStack,
        description: '',
        tags: []
    },
    {
        uniID: 'GraphicArcPie',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicArcPie',
        baseModuleTitle: BaseModuleTitle.graphicArcPie,
        moduleName: 'GraphicArcPie',
        moduleTitle: '圓餅圖形',
        previewData: GraphicArcPie,
        description: '',
        tags: []
    },
    {
        uniID: 'GraphicColumnAreaStack',
        // image: '/images/chartInfo/bar.png',
        baseModuleName: 'graphicColumnAreaStack',
        baseModuleTitle: BaseModuleTitle.graphicColumnAreaStack,
        moduleName: 'GraphicColumnAreaStack',
        moduleTitle: '直立區塊堆疊圖形',
        previewData: GraphicColumnAreaStack,
        description: '',
        tags: ['北市府儀表板']
    },
    {
        uniID: 'GraphicColumnBar',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicColumnBar',
        baseModuleTitle: BaseModuleTitle.graphicColumnBar,
        moduleName: 'GraphicColumnBar',
        moduleTitle: '直立長條圖形',
        previewData: GraphicColumnBar,
        description: '',
        tags: []
    },
    {
        uniID: 'GraphicColumnBarDiverging',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicColumnBarDiverging',
        baseModuleTitle: BaseModuleTitle.graphicColumnBarDiverging,
        moduleName: 'GraphicColumnBarDiverging',
        moduleTitle: '直立分向長條圖形',
        previewData: GraphicColumnBarDiverging,
        description: '',
        tags: []
    },
    {
        uniID: 'GraphicColumnBarGroup',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicColumnBarGroup',
        baseModuleTitle: BaseModuleTitle.graphicColumnBarGroup,
        moduleName: 'GraphicColumnBarGroup',
        moduleTitle: '直立長條群組圖形',
        previewData: GraphicColumnBarGroup,
        description: '',
        tags: []
    },
    // {
    //   uniID: 'GraphicColumnBarPNGroup',
    //   // image: '/images/chartInfo/slider_date_range_line.png',
    //   baseModuleName: 'graphicColumnBarPNGroup',
    //   baseModuleTitle: BaseModuleTitle.graphicColumnBarPNGroup,
    //   moduleName: 'GraphicColumnBarPNGroup',
    //   moduleTitle: '直立長條正負值群組圖形',
    //   previewData: GraphicColumnBarPNGroup,
    //   description: '',
    //   tags: []
    // },
    {
        uniID: 'GraphicColumnBarStack',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicColumnBarStack',
        baseModuleTitle: BaseModuleTitle.graphicColumnBarStack,
        moduleName: 'GraphicColumnBarStack',
        moduleTitle: '直立長條堆疊圖形',
        previewData: GraphicColumnBarStack,
        description: '',
        tags: []
    },
    {
        uniID: 'GraphicColumnLine',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicColumnLine',
        baseModuleTitle: BaseModuleTitle.graphicColumnLine,
        moduleName: 'GraphicColumnLine',
        moduleTitle: '直立折線圖形',
        previewData: GraphicColumnLine,
        description: '',
        tags: []
    },
    {
        uniID: 'GraphicDot',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicDot',
        baseModuleTitle: BaseModuleTitle.graphicDot,
        moduleName: 'GraphicDot',
        moduleTitle: '圓點圖形',
        previewData: GraphicDot,
        description: '',
        tags: []
    },
    {
        uniID: 'GraphicRowBar',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicRowBar',
        baseModuleTitle: BaseModuleTitle.graphicRowBar,
        moduleName: 'GraphicRowBar',
        moduleTitle: '橫長條圖形',
        previewData: GraphicRowBar,
        description: '',
        tags: []
    },
    {
        uniID: 'GraphicRowBarDiverging',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicRowBarDiverging',
        baseModuleTitle: BaseModuleTitle.graphicRowBarDiverging,
        moduleName: 'GraphicRowBarDiverging',
        moduleTitle: '橫分向長條圖形',
        previewData: GraphicRowBarDiverging,
        description: '',
        tags: []
    },
    {
        uniID: 'GraphicRowBarGroup',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicRowBarGroup',
        baseModuleTitle: BaseModuleTitle.graphicRowBarGroup,
        moduleName: 'GraphicRowBarGroup',
        moduleTitle: '橫長條群組圖形',
        previewData: GraphicRowBarGroup,
        description: '',
        tags: []
    },
    {
        uniID: 'GraphicRowBarLayout',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicRowBarLayout',
        baseModuleTitle: BaseModuleTitle.graphicRowBarLayout,
        moduleName: 'GraphicRowBarLayout',
        moduleTitle: '橫長條布局圖形',
        previewData: GraphicRowBarLayout,
        description: '',
        tags: []
    },
    // {
    //   uniID: 'GraphicRowBarPNGroup',
    //   // image: '/images/chartInfo/slider_date_range_line.png',
    //   baseModuleName: 'graphicRowBarPNGroup',
    //   baseModuleTitle: BaseModuleTitle.graphicRowBarPNGroup,
    //   moduleName: 'GraphicRowBarPNGroup',
    //   moduleTitle: '橫長條正負值群組圖形',
    //   previewData: GraphicRowBarPNGroup,
    //   description: '',
    //   tags: []
    // },
    {
        uniID: 'GraphicRowBarStack',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'graphicRowBarStack',
        baseModuleTitle: BaseModuleTitle.graphicRowBarStack,
        moduleName: 'GraphicRowBarStack',
        moduleTitle: '橫長條堆疊圖形',
        previewData: GraphicRowBarStack,
        description: '',
        tags: []
    },
    // {
    //   uniID: 'GraphicImageStack',
    //   // image: '/images/chartInfo/slider_date_range_line.png',
    //   baseModuleName: 'graphicImage',
    //   baseModuleTitle: BaseModuleTitle.graphicImage,
    //   moduleName: 'GraphicImageStack',
    //   moduleTitle: '圖案堆疊圖',
    //   previewData: GraphicImageStack,
    //   description: '',
    //   tags: []
    // },
    {
        uniID: 'SliderDateRangeLine',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'sliderDateRange',
        baseModuleTitle: BaseModuleTitle.sliderDateRange,
        moduleName: 'SliderDateRangeLine',
        moduleTitle: '日期範圍拉軸',
        previewData: SliderDateRangeLine,
        description: '',
        tags: ['蛛思']
    },
    {
        uniID: 'LabelArcPie',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'labelArc',
        baseModuleTitle: BaseModuleTitle.labelArcPie,
        moduleName: 'LabelArcPie',
        moduleTitle: '弧角標籤',
        previewData: LabelArcPie,
        description: '',
        tags: []
    },
    // {
    //   uniID: 'LegendBottom',
    //   // image: '',
    //   baseModuleName: 'legend',
    //   baseModuleTitle: BaseModuleTitle.legend,
    //   moduleName: 'LegendBottom',
    //   moduleTitle: '下方圖例',
    //   previewData: LegendBottom,
    //   description: '',
    //   tags: []
    // },
    {
        uniID: 'TooltipAside',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'tooltip',
        baseModuleTitle: BaseModuleTitle.tooltip,
        moduleName: 'TooltipAside',
        moduleTitle: '文字側邊提示',
        previewData: TooltipAside,
        description: '',
        tags: []
    },
    {
        uniID: 'TooltipFollowing',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'tooltip',
        baseModuleTitle: BaseModuleTitle.tooltip,
        moduleName: 'TooltipFollowing',
        moduleTitle: '文字跟隨提示',
        previewData: TooltipAside,
        description: '',
        tags: []
    },
    {
        uniID: 'UtilAuxDateLabel',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'utilAuxDateLabel',
        baseModuleTitle: BaseModuleTitle.utilAuxDateLabel,
        moduleName: 'UtilAuxDateLabel',
        moduleTitle: '補助日期標籤',
        previewData: UtilAuxDateLabel,
        description: '',
        tags: []
    },
    {
        uniID: 'UtilAuxLine',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'utilAuxLine',
        baseModuleTitle: BaseModuleTitle.utilAuxLine,
        moduleName: 'UtilAuxLine',
        moduleTitle: '輔助線',
        previewData: UtilAuxLine,
        description: '',
        tags: []
    },
    {
        uniID: 'UtilGraphicBarLabel',
        // image: '/images/chartInfo/slider_date_range_line.png',
        baseModuleName: 'utilGraphicBarLabel',
        baseModuleTitle: BaseModuleTitle.utilGraphicBarLabel,
        moduleName: 'UtilGraphicBarLabel',
        moduleTitle: '長條圖標籤',
        previewData: UtilGraphicBarLabel,
        description: '',
        tags: []
    },
];
